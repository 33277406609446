import './css/App.scss';
import Banner from './Banner';
import Footer from './Footer';
import Form from './Form';
import Hero from './Hero';
import { createRef } from 'react';

function App() {
  const formref = createRef();

  return (
    <div className="App">
      <Banner/>
      <Hero elementref={formref}/>
      <Form elementref={formref}/>
      <Footer/>
    </div>
  );
}

export default App;
