function Footer() {
    return(
        <div className="Footer">
            <p className="FooterText">
                Dacă preferi să completezi formularul fizic, <a target="_blank" rel="noopener noreferrer" className="Link" href="/formular-230-2023.pdf">dă click aici pentru a-l descărca</a> sau salvează-l dând click pe poza de mai jos. Printează-l, completează-l și apoi trimite-l scanat la asociatianoel@gmail.com
            </p>
            <img alt="footer" className="FooterFormular" src="/formular-230.png"/>
        </div>
    );
}

export default Footer;