import { createRef, useState } from "react";
import CanvasDraw from "react-canvas-draw";
import './css/Form.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ErrorSection from "./components/ErrorSection";
import useCheckMobileScreen from "./util";

export const FORM_SUBMIT_SUCCESS = 'https://noelsma1.ro/multumim/';

function Form(props) {
    const {
        elementref
    } = props

    const [canvasvalue, setCanvasvalue] = useState('')
    const [errors, setErrors] = useState({})
    const canvasRef = createRef(null)
    const isMobile = useCheckMobileScreen()

    const clear = (e) => {
        if (e) {
            e.preventDefault();
        }

        setCanvasvalue('')
        canvasRef.current.clear();
    }

    const onSemnaturaChange = (e) => {
        const image = canvasRef.current.canvasContainer.children[1].toDataURL('image/jpg');
        setCanvasvalue(image)
    }

    const onSubmit = async (e) => {
        setErrors({})
        e.preventDefault()

        if (!canvasvalue) {
            toast.error('Vă rugăm semnați formularul!')
            return;
        }

        const formData = new FormData(document.getElementById('form-donate'))

        let object = {};

        formData.forEach(function (value, key) {
            object[key] = value;
        });

        try {
            const tokenData = await fetch('/sanctum/csrf-token', {
                method: 'GET',
                credentials: 'include'
            })

            const { token = '' } = await tokenData.json()

            if (!token) {
                throw new Error('Invalid token!')
            }

            const response = await fetch('/genereaza-formular', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Csrf-Token': token,
                    'X-Xsrf-Token': token
                },
                body: JSON.stringify(object),
            });

            if (!response.ok) {
                toast.error('Eroare, vă rugăm încercați mai târziu!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    type: "error"
                });

                return;
            }

            const {
                message = '',
                errors = {},
                status = 'success'
            } = await response.json();

            if (status === 'error') {
                if (errors) {
                    setErrors(errors)
                }

                toast.error(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    type: "error"
                });
            } else {
                document.getElementById("form-donate").reset();

                toast.success(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    type: "success"
                });

                window.location.replace(FORM_SUBMIT_SUCCESS);
            }
        } catch (error) {
            toast.error(error?.message || 'A apărut o eroare', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }

        elementref.current.scrollIntoView();
    }
    return (
        <div className="Form">
            <ToastContainer theme="colored" />
            <div className="m-form-container type-light m-form-container">

                <form name="form-donate" className="m-form m-form" id="form-donate" method="POST" onSubmit={onSubmit}>

                    <div ref={elementref} className="m-form-body">
                        <div className="m-form-section">
                            <div className="m-form-fields">

                                <div className="form-group form-group-text third">
                                    <label className="form-group-label" htmlFor="sponsorizare-nume">Numele de familie (*)</label>
                                    <input className="form-control" required type="text" name="nume" id="sponsorizare-nume" />
                                    <ErrorSection errors={errors} name="nume" />
                                </div>
                                <div className="form-group form-group-text third">
                                    <label className="form-group-label" htmlFor="sponsorizare-initiala">Inițiala tatălui</label>
                                    <input className="form-control" type="text" name="initiala" id="sponsorizare-initiala" />
                                    <ErrorSection errors={errors} name="initiala" />
                                </div>
                                <div className="form-group form-group-text third">
                                    <label className="form-group-label" htmlFor="sponsorizare-prenume">Prenume (*)</label>
                                    <input className="form-control" required type="text" name="prenume" id="sponsorizare-prenume" />
                                    <ErrorSection errors={errors} name="prenume" />
                                </div>
                                <div className="form-group form-group-text third">
                                    <label className="form-group-label" htmlFor="sponsorizare-cnp">CNP (*)</label>
                                    <input className="form-control" required type="text" minLength="13" maxlength="13" name="cnp" id="sponsorizare-cnp" />
                                    <ErrorSection errors={errors} name="cnp" />
                                </div>
                                <div className="form-group form-group-text third">
                                    <label className="form-group-label" htmlFor="sponsorizare-email">Adresa Email</label>
                                    <input className="form-control" type="email" placeholder="Vei primi formularul pe acest email" name="email" id="sponsorizare-email" />
                                    <ErrorSection errors={errors} name="email" />
                                </div>
                                <div className="form-group form-group-text third">
                                    <label className="form-group-label" htmlFor="sponsorizare-telefon">Număr de telefon</label>
                                    <input className="form-control" type="text" name="telefon" id="sponsorizare-telefon" />
                                    <ErrorSection errors={errors} name="telefon" />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="m-form-section">
                            <h3 className="m-form-section-title">Adresa</h3>
                            <div className="m-form-fields-two">
                                <div className="form-group form-group-text half">
                                    <label className="form-group-label" htmlFor="sponsorizare-localitate">Localitatea (*)</label>
                                    <input className="form-control" required type="text" name="localitate" id="sponsorizare-localitate" />
                                    <ErrorSection errors={errors} name="localitate" />
                                </div>
                                <div className="form-group form-group-text half">
                                    <label className="form-group-label" htmlFor="sponsorizare-judet">Județ (*)</label>
                                    <select className="form-control" required name="judet" id="sponsorizare-judet">
                                        <option value="">-</option>
                                        <option value="Alba">Alba</option>
                                        <option value="Arad">Arad</option>
                                        <option value="Arges">Arges</option>
                                        <option value="Bacau">Bacau</option>
                                        <option value="Bihor">Bihor</option>
                                        <option value="Bistrita Nasaud">Bistrita Nasaud</option>
                                        <option value="Botosani">Botosani</option>
                                        <option value="Brasov">Brasov</option>
                                        <option value="Braila">Braila</option>
                                        <option value="Bucuresti">Bucuresti</option>
                                        <option value="Buzau">Buzau</option>
                                        <option value="Caras Severin">Caras Severin</option>
                                        <option value="Calarasi">Calarasi</option>
                                        <option value="Cluj">Cluj</option>
                                        <option value="Constanta">Constanta</option>
                                        <option value="Covasna">Covasna</option>
                                        <option value="Dambovita">Dambovita</option>
                                        <option value="Dolj">Dolj</option>
                                        <option value="Galati">Galati</option>
                                        <option value="Giurgiu">Giurgiu</option>
                                        <option value="Gorj">Gorj</option>
                                        <option value="Harghita">Harghita</option>
                                        <option value="Hunedoara">Hunedoara</option>
                                        <option value="Ialomita">Ialomita</option>
                                        <option value="Iasi">Iasi</option>
                                        <option value="Ilfov">Ilfov</option>
                                        <option value="Maramures">Maramures</option>
                                        <option value="Mehedinti">Mehedinti</option>
                                        <option value="Mures">Mures</option>
                                        <option value="Neamt">Neamt</option>
                                        <option value="Olt">Olt</option>
                                        <option value="Prahova">Prahova</option>
                                        <option value="Satu Mare">Satu Mare</option>
                                        <option value="Salaj">Salaj</option>
                                        <option value="Sibiu">Sibiu</option>
                                        <option value="Suceava">Suceava</option>
                                        <option value="Teleorman">Teleorman</option>
                                        <option value="Timis">Timis</option>
                                        <option value="Tulcea">Tulcea</option>
                                        <option value="Vaslui">Vaslui</option>
                                        <option value="Valcea">Valcea</option>
                                        <option value="Vrancea">Vrancea</option>
                                    </select>
                                </div>
                                <div className="form-group form-group-text half">
                                    <label className="form-group-label" htmlFor="sponsorizare-strada">Strada</label>
                                    <input className="form-control" type="text" name="strada" id="sponsorizare-strada" />
                                    <ErrorSection errors={errors} name="strada" />
                                </div>
                                <div className="form-group form-group-text half">
                                    <label className="form-group-label" htmlFor="sponsorizare-nr">Număr</label>
                                    <input className="form-control" type="text" name="nr" id="sponsorizare-nr" />
                                    <ErrorSection errors={errors} name="nr" />
                                </div>
                                <div className="form-group form-group-text third">
                                    <label className="form-group-label" htmlFor="sponsorizare-bloc">Bloc</label>
                                    <input className="form-control" type="text" name="bloc" id="sponsorizare-bloc" />
                                    <ErrorSection errors={errors} name="bloc" />
                                </div>
                                <div className="form-group form-group-text third">
                                    <label className="form-group-label" htmlFor="sponsorizare-scara">Scara</label>
                                    <input className="form-control" type="text" name="scara" id="sponsorizare-scara" />
                                    <ErrorSection errors={errors} name="scara" />
                                </div>
                                <div className="form-group form-group-text third">
                                    <label className="form-group-label" htmlFor="sponsorizare-etaj">Etaj</label>
                                    <input className="form-control" type="text" name="etaj" id="sponsorizare-etaj" />
                                    <ErrorSection errors={errors} name="etaj" />
                                </div>
                                <div className="form-group form-group-text third">
                                    <label className="form-group-label" htmlFor="sponsorizare-ap">Apartament</label>
                                    <input className="form-control" type="text" name="ap" id="sponsorizare-ap" />
                                    <ErrorSection errors={errors} name="ap" />
                                </div>

                                <div className="form-group form-group-text third">
                                    <label className="form-group-label" htmlFor="sponsorizare-cod_postal">Cod Poștal</label>
                                    <input className="form-control" type="text" name="cod_postal" id="sponsorizare-cod_postal" />
                                    <ErrorSection errors={errors} name="cod_postal" />
                                </div>

                            </div>
                            <div className="m-form-section form-additional">
                                <div className="form-group form-group-text">
                                    <label className="form-group-label" htmlFor="sponsorizare-bifa_2_ani">Vreau sa redirecționez pe perioada de 2 ani</label>
                                    <div className="form-check">
                                        <input className="form-check-input" checked="true" type="radio" name="redirectionare" value="on" id="sponsorizare-bifa_2_ani_da" />
                                        <label className="form-check-label" htmlFor="sponsorizare-bifa_2_ani_da">
                                            DA
                                        </label>

                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="redirectionare" value="off" id="sponsorizare-bifa_2_ani_nu" />
                                        <label className="form-check-label" htmlFor="sponsorizare-bifa_2_ani_nu">
                                            NU
                                        </label>
                                    </div>

                                </div>
                                <div className="form-group form-group-text">
                                    <label className="form-group-label">Semnătura</label>
                                    <CanvasDraw lazyRadius={isMobile ? 1 : 10} onChange={onSemnaturaChange} ref={canvasRef} className="semnatura-canvas" hideGrid hideInterface brushRadius={2} canvasHeight={200} canvasWidth={isMobile ? 400 : 600} />
                                    <button className="ButtonHollow sterge-button" onClick={clear}>Șterge semnătura</button>
                                    <input className="form-control" value={canvasvalue} type="hidden" name="semnatura" id="sponsorizare-semnatura" />
                                    <ErrorSection errors={errors} name="semnatura" />
                                </div>

                                <div className="form-group form-group-checkbox form-check">

                                    <input className="form-check-input" required type="checkbox" name="agreement" id="" />
                                    <label className="form-check-label">Sunt de acord cu <a className="Link" href="https://asociatianoel.ro/politica-de-confidentialitate/" rel="noopener noreferrer" target="_blank">Politica de confidențialitate</a> și declar că am citit informațiile necesare în concordanță cu <a className="Link" href="https://www.privacy-regulation.eu/ro/13.htm" rel="noopener noreferrer" target="_blank">Articol 13 GDPR</a>.</label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="m-form-footer">
                        <button className="Button submit-button" type="submit">Generează Formular</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Form;
