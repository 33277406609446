function ScrollToButton(props) {
    const {
        elementref,
        type = 'button',
        children,
        className = ''
    } = props;

    const handleScrollButton = (e) => {
        e.preventDefault();

        elementref.current.scrollIntoView();
    }

    if(type === 'link') {
        return (
            <a href="/" className={`Link ${className}`} onClick={handleScrollButton}>{children}</a>
        )
    }

    return(
        <button className={`ButtonHollow ${className}`} onClick={handleScrollButton}>{children}</button>
    )
}

export default ScrollToButton;