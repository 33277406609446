import './css/Hero.scss'
import ScrollToButton from './components/ScrollToButton';

function Hero(props) {
    const {
        elementref
    } = props

    return(
        <div className="Hero">
            <div className="HeaderWrapper">
                <h3 className="Header">
                    Donează gratuit 3.5% din impozitul pe venit completând Formularul 230
                </h3>
                <ScrollToButton elementref={elementref} className="HeaderButton">Vezi formular</ScrollToButton>
            </div>
           <div className="HeroContent">
                <p><ScrollToButton elementref={elementref} type="link">Click aici să completezi formularul online</ScrollToButton> SAU <a target="_blank" rel="noopener noreferrer" className="Link" href="/formular-230-2023.pdf">Click aici să descarci formularul pentru print.</a></p>
                <p>Completați formularul 230 dacă în anul 2023 ați fost <b>angajat</b> sau <b>pensionar.</b> Prin completarea formularului, solicitați statului român să redirecționeze către Asociatia Noel 3.5% din impozitul pe venit pe care l-ați plătit deja. Termenul limită pentru completare este 25 mai 2024.</p>
                <p>Exemplu: Dacă ați avut salariu 2000lei, statul va vira 90 lei (adică 3,5% din impozitul pe salariu).</p>
                <p>Asociatia Noel ajută copii care au nevoie de tratamente costitisitoare. Cu ajutorul formularului 230 vom reuși să le achităm tratamentele!</p>
                <h4>Completează mai jos și se va genera formularul pe care îl vei primi pe mail:</h4>
           </div>
        </div>
    )
}

export default Hero;