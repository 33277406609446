import '../css/Error.scss'

function ErrorSection(props) {
    const {
        errors = {},
        name = ''
    } = props
    
    const specErrors = errors?.[name] || []

    if(!specErrors || specErrors.length === 0) {
        return null;
    }

    return (
        <div className="ErrorWrapper">
            {specErrors.map((error) => <span className="ErrorMessage">{error}</span>)}
        </div>
    )
    
}

export default ErrorSection;